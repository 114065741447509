import './CardSendMail.scss';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form'
import axios from "axios";
import { ServerResponseModal } from "../ServerResponseModal/ServerResponseModal";

const url = {
  teras: 'https://constructor.teras-mebel.ru/PHPMailer/constructor_form.php',
  akmetron: 'https://akmetron.ru/PHPMailer/constructor_form.php',
}

const CardSendMail = ({itemsArray, clearFunc,fileImage}) => {
  const { register, handleSubmit, formState: {errors} } = useForm();
  const [isFetching, setIsFetching] = useState(false);
  const [errorPopup, setErrorPopup] = useState(false);
  const [successPopup, setSuccessPopup] = useState(false);
  
 const onSubmit = async (data) => {
    setIsFetching(true);

    const resultArray = [data, ...itemsArray];
    const formData = new FormData();

    // Отправляем `data` как JSON-строку
    formData.append("data", JSON.stringify(resultArray));

    // Конвертация base64 в Blob (если есть fileImage)
    if (fileImage) {
        const byteString = atob(fileImage.split(',')[1]); // Декодируем base64
        const mimeString = fileImage.split(',')[0].split(':')[1].split(';')[0]; // Получаем MIME-тип
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const uint8Array = new Uint8Array(arrayBuffer);

        for (let i = 0; i < byteString.length; i++) {
            uint8Array[i] = byteString.charCodeAt(i);
        }

        const blob = new Blob([uint8Array], { type: mimeString }); // Создаем Blob
        formData.append("fileImage", blob, "table.png"); // Добавляем в FormData
    }

    try {
        const response = await axios.post(url.teras, formData, {
            headers: {
                'Accept': '*/*',
                'Content-Type': 'multipart/form-data'
            }
        });

        if (response.status === 200) { 
            setSuccessPopup(true);
        } else { 
            setErrorPopup(true);
        }
    } catch (e) {
        setErrorPopup(true);
        console.error(`Ошибка сервера: ${e.message}`);
    }
    
    setIsFetching(false);
};

  return (
    <div className='CardSendMail'>
      <h4 className='CardSendMail__title'>Сделать заказ</h4>
      <form onSubmit={handleSubmit(onSubmit)} className='CardSendMail__form'>
        <span className='CardSendMail__formItemContainer'>
          {!!errors.nameUser && <span className='CardSendMail__errorMessage'>{errors.nameUser.message}</span>}
          <input className='CardSendMail__input' type='text' placeholder='Название компании/ФИО' {...register('nameUser', {
                    required: {value: true, message: 'Это обязательное поле'},
                  })}/>
        </span>
        <span className='CardSendMail__formItemContainer'>
          {!!errors.phone && <span className='CardSendMail__errorMessage'>{errors.phone.message}</span>}
          <input className='CardSendMail__input' type='text' placeholder='Номер телефона' {...register('phone', {
                    required: {value: true, message: 'Это обязательное поле'},
                  })}/>
        </span>
        <span className='CardSendMail__formItemContainer'>
          {!!errors.email && <span className='CardSendMail__errorMessage'>{errors.email.message}</span>}
          <input className='CardSendMail__input' type='text' placeholder='Email' {...register('email', {
                    required: {value: true, message: 'Это обязательное поле'},
                    pattern: {value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g, message: 'Некорректный email'}
                  })}/>
        </span>
        <span className='CardSendMail__formItemContainer'>
          {!!errors.inn && <span className='CardSendMail__errorMessage'>{errors.inn.message}</span>}
          <input className='CardSendMail__input' type='text' placeholder='ИНН' {...register('inn', {
            required: {value: true, message: 'Это обязательное поле'},
          })}/>
        </span>
        <input className='CardSendMail__input' type='text' placeholder='Комментарий к заказу' {...register('comment')}/>
        <div className='CardSendMail__actions'>
          <button className='CardSendMail__button' type='submit' disabled={isFetching}>Отправить</button>
          <p className='polit'>Нажимая на кнопку, вы соглашаетесь с <a href="https://teras-mebel.ru/politika-konfidentsialnosti">политикой конфиденциальности</a></p>
          <button className='CardSendMail__button' type='button' onClick={clearFunc}>Сбросить всё</button>
        </div>
      </form>
      {errorPopup &&
        <ServerResponseModal title='Ошибка сервера' onClose={() => setErrorPopup(false)} type='error'>
          Произошла ошибка сервера, повторите попытку позже.
        </ServerResponseModal>
      }
      {successPopup &&
      <ServerResponseModal title='Спасибо за Ваше обращение' onClose={() => setSuccessPopup(false)} type='success'>
        Сотрудники нашей компании свяжутся с Вами в ближайшее время.
      </ServerResponseModal>
      }
    </div>
  );
};

export { CardSendMail };